<template>
  <div>
    <h2 v-if="!skipHeader">Kiosk mode</h2>
    <p class="text-justify" v-if="!skipHeader">
      {{
        $t(
          "Care Unit real-time overview & alerts"
        )
      }}
    </p>

            
    <b-tabs no-body pills card justified content-class="isa-link" nav-item-class="isa-link">
      <b-tab no-body pills :title="$t('Regular States')" content-class="isa-link" >
        <b-tabs   no-body vertical pills card  content-class="isa-link">
          <b-tab v-for="hstate in states" :key="hstate.title" :title="hstate.title" title-link-class ="isa-link">
            <template #title>
              <div class="d-flex" style="margin-bottom: 0px">
                <img
                class="d-flex m-2 rounded-circle img-thumbnail thumb-lg"
                :src="hstate.logo"
                width="25px"
                />
                <span class="m-2">{{ hstate.title }}</span>
              </div>
            </template>
            <ISARoom
            :roomset="hstate.roomset"
            :room="hstate.room"
            :onBtnHelp="onBtnHelp"
            :onBtnPower="onBtnPower"
            :onBtnEye="onBtnEye"
            :onBtnBed="onBtnBed"
            :onBtnAction="onBtnAction"
            />
            <br/>
            <span>{{hstate.description}}</span>
          </b-tab>                                                        
        </b-tabs>
      </b-tab>
      <b-tab no-body pills :title="$t('Alert Modes')">
        <b-tabs   no-body vertical pills card  content-class="isa-link">
          <b-tab v-for="hstate in alertStates" :key="hstate.title" :title="hstate.title" title-link-class ="isa-link">
            <template #title>
              <div class="d-flex" style="margin-bottom: 0px">
                <img
                class="d-flex m-2 rounded-circle img-thumbnail thumb-lg"
                :src="hstate.logo"
                width="25px"
                />
                <span class="m-2">{{ hstate.title }}</span>
              </div>
            </template>
            <ISARoom
            :roomset="roomset"
            :room="hstate.room"
            :onBtnHelp="onBtnHelp"
            :onBtnPower="onBtnPower"
            :onBtnEye="onBtnEye"
            :onBtnBed="onBtnBed"
            :onBtnAction="onBtnAction"
            />
            <br/>
            <span>{{hstate.description}}</span> <br/>
            <span>{{hstate.description2}}</span><br/>
            <span>{{hstate.description3}}</span><br/>
          </b-tab>                                                        
        </b-tabs>
      </b-tab>
      <b-tab no-body pills :title="$t('Actions')">
        <b-tabs   no-body vertical pills card  content-class="isa-link">
          <b-tab v-for="hstate in actions" :key="hstate.title" :title="hstate.title" title-link-class ="isa-link">
            <template #title>
              <div class="d-flex" style="margin-bottom: 0px">
                <font-awesome-icon :icon="hstate.icon" style="margin-left: 0rem; font-size: 2rem;" class="icon alt" />
                  <span class="m-2">{{ hstate.title }}</span>
              </div>
            </template>                
            <ISARoom
            :ref="hstate.ref"
            :roomset="hstate.roomset"
            :room="hstate.room"
            :onBtnHelp="onBtnHelp"
            :onBtnPower="onBtnPower"
            :onBtnEye="onBtnEye"
            :onBtnBed="onBtnBed"
            :onBtnAction="onBtnAction"
            :docBtnHelp="hstate.docBtnHelp"
            :docBtnPower="hstate.docBtnPower"
            :docBtnEye="hstate.docBtnEye"
            :docBtnBed="hstate.docBtnBed"
            :docBtnAction="hstate.docBtnAction"
            />
            <br/>
            <span>{{hstate.description}}</span>
          </b-tab>                                                        
        </b-tabs>
      </b-tab>
    </b-tabs>
    
  </div>
</template>
<script>
import { IMGService } from "@/services/img.service";
import ISARoom from "@/components/ISA/kiosk/ISARoom.vue";

export default {
  components: {
    ISARoom,
  },
  data() {
    return {
      IMGService,
    };
  },
  props: {    
    skipHeader: { type: Boolean },
  },
  computed: {
    roomset() {
      return {
        timezone: "UTC",
        label: "CareUnit",
      };
    },
    states() {
      let st = [
          /*********   normal */ 
          {
            title: this.$t('Nothing to report'),
            description: this.$t('ISA is runnig well and has no particular detection to report.'),
            logo: IMGService.logo,
            roomset: {
              timezone: "UTC",
              label: "Care Unit",
              licensing: {
                closeFollowUp: true,
              },
              featureCloseFollowUpEnabled: true,
            },
            room:  {
              label: this.$t("Room name"),              
              sensor: {
                isOnline: 1,
                settings: {
                  enabled: true,
                },
              },
              admissionList: [
                {
                  stableState: { code: 0 },
                  alert: {
                    moderation: { createdAt: null, trueAlert: false },
                    handling: { createdAt: null },
                    alertType: 4,
                  },
                },
              ],
            }        
          },
        /* Features are disabled, hiding from help menu
          {
            title: this.$t('Walking'),
            description: this.$t('ISA detects someone walking in the room.'),
            logo: IMGService.move,
            roomset: {
              timezone: "UTC",
              label: "Care Unit",
              licensing: {
                closeFollowUp: true,
              },
              featureCloseFollowUpEnabled: true,
            },
            room:  {      
              label: this.$t("Room name"),              
              sensor: {
                isOnline: 1,
                settings: {
                  enabled: true,
                  enableFollowUp: true,
                },
              },
              admissionList: [
                {
                  stableState: { code: 10 },
                  alert: {
                    moderation: { createdAt: null, trueAlert: false },
                    handling: { createdAt: null },
                    alertType: 4,
                  },
                },
              ],
            }        
          },
          {
            title: this.$t('Laying in bed'),
            description: this.$t('ISA detects someone in its bed.'),
            logo: IMGService.sleep,
            roomset: {
              timezone: "UTC",
              label: "Care Unit",
              licensing: {
                closeFollowUp: true,
              },
              featureCloseFollowUpEnabled: true,
            },
            room:  {      
              label: this.$t("Room name"),              
              sensor: {
                isOnline: 1,
                settings: {
                  enabled: true,
                },
              },
              admissionList: [
                {
                  stableState: { code: 30 },
                  alert: {
                    moderation: { createdAt: null, trueAlert: false },
                    handling: { createdAt: null },
                    alertType: 4,
                  },
                },
              ],
            }        
          },
          {
            title: this.$t('Agitated in bed'),
            description: this.$t('The person in the bed is agitated.'),
            logo: IMGService.wakeUp,
            roomset: {
              timezone: "UTC",
              label: "Care Unit",
              licensing: {
                closeFollowUp: true,
              },
              featureCloseFollowUpEnabled: true,
            },
            room:  {      
              label: this.$t("Room name"),              
              sensor: {
                isOnline: 1,
                settings: {
                  enabled: true,
                },
              },
              admissionList: [
                {
                  stableState: { code: 31 },
                  alert: {
                    moderation: { createdAt: null, trueAlert: false },
                    handling: { createdAt: null },
                    alertType: 4,
                  },
                },
              ],
            }        
          },
        +/
           /*********   Disabled */ 
          {
            title: this.$t('Disabled'),
            description: this.$t('ISA is disabled in this room. You can enable it by cliking the Power icone. Be aware that no detection will occure when ISA is disabled.'),
            logo: IMGService.sensorOff,
            roomset: {
              timezone: "UTC",
              label: "Care Unit",
              licensing: {
                closeFollowUp: true,
              },
              featureCloseFollowUpEnabled: true,
            },
            room:  {      
              label: this.$t("Room name"),              
              sensor: {
                isOnline: 1,
                settings: {
                  enabled: false,
                },
              },
              admissionList: [
                {
                  stableState: { code: 31 },
                  alert: {
                    moderation: { createdAt: null, trueAlert: false },
                    handling: { createdAt: null },
                    alertType: 4,
                  },
                },
              ],
            }        
          },
           /*********   Sensor offline */ 
          {
            title: this.$t('Offline'),
            description: this.$t('The sensor is offline in this room. Please check if the sensor has power and is connected to the Internet. Be aware that no detection will occure when the sensor is offline.'),
            logo: IMGService.timeout,
            roomset: {
              timezone: "UTC",
              label: "Care Unit",
              licensing: {
                closeFollowUp: true,
              },
              featureCloseFollowUpEnabled: true,
            },
            room:  {      
              label: this.$t("Room name"),              
              sensor: {
                isOnline: -1,
                settings: {
                  enabled: false,
                },
              },
              admissionList: [
                {
                  stableState: { code: 31 },
                  alert: {
                    moderation: { createdAt: null, trueAlert: false },
                    handling: { createdAt: null },
                    alertType: 4,
                  },
                },
              ],
            }        
          },
           /*********   Setup */ 
          {
            title: this.$t('Setup'),
            description: this.$t("The sensor is currently in the setup phase. Our team is diligently working to ensure everything is prepared as swiftly as possible. For further assistance or information, please don't hesitate to reach out to MintT."),
            logo: IMGService.setup,
            roomset: {
              timezone: "UTC",
              label: "Care Unit",
              licensing: {
                closeFollowUp: true,
              },
              featureCloseFollowUpEnabled: true,
            },
            room:  {      
              label: this.$t("_Room name"),              
              sensor: {
                isOnline: 0,
                settings: {
                  enabled: true,
                },
              },
              admissionList: [
                {
                  stableState: { code: 31 },
                  alert: {
                    moderation: { createdAt: null, trueAlert: false },
                    handling: { createdAt: null },
                    alertType: 4,
                  },
                },
              ],
            }        
          },
      ];      
      return st;
    },
    alertStates() {
      let st = [
          /*********   fall alert */ 
          {
            title: this.$t('Fall alert'),
            description: this.$t('A fall has been detected. In addition to the alert displayed here, the phones configured to get the alert have been contacted.'),
            description2: this.$t(" To acknowledge the alert, you can click the Fall button. The alert will also be acknowledged by hanging up the phone after the voice message."),
            description3: this.$t("It is important to acknowledge the alert as no new alert will be triggered in the current room until the acknowledgment."),
            logo: IMGService.fall,
            room:  {      
              label: this.$t("Room name"),              
              sensor: {
                isOnline: 1,
                settings: {
                  enabled: true,
                },
              },
              admissionList: [
                {
                  stableState: { code: 0 },
                  alert: {
                    moderation: { createdAt: new Date(), trueAlert: true },
                    handling: { createdAt: null },
                    autoHandling: { createdAt: null },
                    alertType: 5,
                  },
                },
              ],
            }        
          },
          /*********   Bed exit alert */ 
          {
            title: this.$t('Bed Exit'),
            description: this.$t('A bed exit has been detected. Bed Exit Alerts are detected only with configured sensors and within the scheduled time period (see Care Units & Sensor Management Menu).'),
            description2: this.$t("To acknowledge the alert, click the blincking Bed Exit button. The alert will also be acknowledged by hanging up the phone after the voice message."),
            description3: this.$t("If a Fall alert occures during a Bed Exit alert, the Bed Exit Alert will be auto handle and replaced by the Fall Alert."),
            logo: IMGService.move,
            room:  {      
              label: this.$t("Room name"),              
              sensor: {
                isOnline: 1,
                settings: {
                  enabled: true,
                },
              },
              admissionList: [
                {
                  stableState: { code: 10 },
                  alert: {
                    moderation: { createdAt: new Date(), trueAlert: true },
                    handling: { createdAt: null },
                    autoHandling: { createdAt: null },
                    alertType: 4,
                  },
                },
              ],
            }        
          },
          /*********   needs handling exit alert */ 
          {
            title: this.$t('Post Alert Acknowledgment'),
            description: this.$t('Once a Fall Alert or Bed Exit Alert has been acknowledge and the Patient has been secured, click  the blincking Patient Helped button.'),
            description2: this.$t("This is useful to track the correct statistics."),
            description3: this.$t(""),
            logo: IMGService.takeCare,
            room:  {      
              label: this.$t("Room name"),              
              sensor: {
                isOnline: 1,
                settings: {
                  enabled: true,
                },
              },
              admissionList: [
                {
                 expectingHandled: true,
                  stableState: { code: 0 },
                  alert: {
                    moderation: { createdAt: new Date(), trueAlert: true },
                    handling: { createdAt: new Date() },
                    autoHandling: { createdAt: null },
                    alertType: 4,
                  },
                },
              ],
            }        
          },        
      ];      
      return st;
    },
    actions() {
      // let test =  this.$refs.po && this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp ? this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp.parentElement.getBoundingClientRect() : 'blabla';
      // console.log(test)
      let st = [
        {
          title: this.$t('Disable Sensor'),
          icon: 'power-off',
          ref: 'po',
          target_x: this.$refs.po && this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp ? this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp.getBoundingClientRect().left : 0,
          target_y: this.$refs.po && this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp ? this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp.getBoundingClientRect().top : 0,
          roomset: {
            timezone: "UTC",
            label: "Care Unit",
            licensing: {
              closeFollowUp: true,
            },
            featureCloseFollowUpEnabled: true,
          },
          room:  {      
            oid: 'funkyhelp',
            label: this.$t("Room name"),              
            sensor: {
              isOnline: 1,
              settings: {
                enabled: true,
                enableFollowUp: true,
                enableBedExitDetection: true,
              },
            },
            admissionList: [
              {
                stableState: { code: 0 },
                alert: {
                  moderation: { createdAt: new Date(), trueAlert: false },
                  handling: { createdAt: null },
                  autoHandling: { createdAt: null },
                  alertType: 5,
                },
              },
            ],
          },
          docBtnHelp: false,
          docBtnPower: true,
          docBtnEye: false,
          docBtnBed: false,
          docBtnAction: false,
          description: this.$t('By clicking this button, you\'ll disable the sensor. No data are processed and no alert will be sent. The room is not secured anymore.'),
        },
        {
          title: this.$t('Enable Sensor'),
          icon: 'power-off',
          ref: 'po',
          target_x: this.$refs.po && this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp ? this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp.getBoundingClientRect().left : 0,
          target_y: this.$refs.po && this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp ? this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp.getBoundingClientRect().top : 0,
          roomset: {
            timezone: "UTC",
            label: "Care Unit",
            licensing: {
              closeFollowUp: true,
            },
            featureCloseFollowUpEnabled: true,
          },
          room:  {      
            oid: 'funkyhelp',
            label: this.$t("Room name"),              
            sensor: {
              isOnline: 1,
              settings: {
                enabled: false,
              },
            },
            admissionList: [
              {
                stableState: { code: 0 },
                alert: {
                  moderation: { createdAt: new Date(), trueAlert: false },
                  handling: { createdAt: null },
                  autoHandling: { createdAt: null },
                  alertType: 5,
                },
              },
            ],
          },
          docBtnHelp: false,
          docBtnPower: true,
          docBtnEye: false,
          docBtnBed: false,
          docBtnAction: false,
          description: this.$t('If your sensor is disabled, clicking this button will re-enable the sensor.'),
        },
        {
          title: this.$t('Bed Exit Settings'),
          icon: 'procedures',
          ref: 'po',
          target_x: this.$refs.po && this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp ? this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp.getBoundingClientRect().left : 0,
          target_y: this.$refs.po && this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp ? this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp.getBoundingClientRect().top : 0,
          roomset: {
            timezone: "UTC",
            label: "Care Unit",
            licensing: {
              closeFollowUp: true,
            },
            featureCloseFollowUpEnabled: true,
          },
          room:  {      
            oid: 'funkyhelp',
            label: this.$t("Room name"),              
            sensor: {
              isOnline: 1,
              settings: {
                enabled: true,
                enableBedExitDetection: true,
                enableFollowUp: true,
              },
            },
            admissionList: [
              {
                stableState: { code: 0 },
                alert: {
                  moderation: { createdAt: new Date(), trueAlert: false },
                  handling: { createdAt: null },
                  autoHandling: { createdAt: null },
                  alertType: 5,
                },
              },
            ],
          },
          docBtnHelp: false,
          docBtnPower: false,
          docBtnEye: false,
          docBtnBed: true,
          docBtnAction: false,
          description: this.$t('By clicking this button, you\'ll be able to enable and configure the Bed Exit alert for this room. First tick the "Enable bed exit detection" then choose the appropriate time schedule during which the bed exit will be be detected.'),
        },
        {
          title: this.$t('Close follow up'),
          icon: 'eye',
          ref: 'po',
          target_x: this.$refs.po && this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp ? this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp.getBoundingClientRect().left : 0,
          target_y: this.$refs.po && this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp ? this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp.getBoundingClientRect().top : 0,
          roomset: {
            timezone: "UTC",
            label: "Care Unit",
            licensing: {
              closeFollowUp: true,
            },
            featureCloseFollowUpEnabled: true,
          },
          room:  {      
            label: this.$t("Room name"),              
            sensor: {
              isOnline: 1,
              settings: {
                enabled: true,
                enableFollowUp: true,
                enableBedExitDetection: true,
              },
            },
            admissionList: [
              {
                stableState: { code: 0 },
                alert: {
                  moderation: { createdAt: new Date(), trueAlert: false },
                  handling: { createdAt: null },
                  autoHandling: { createdAt: null },
                  alertType: 5,
                },
              },
            ],
          },
          docBtnHelp: false,
          docBtnPower: false,
          docBtnEye: true,
          docBtnBed: false,
          docBtnAction: false,
          description: this.$t('For patients with high risk of falling, the nurse can make sure the patient is safe at any moment, including after an alert by viewing a live stream of the room. '),
        },
      /*
      {
        title: this.$t('Handle a Fall alert'),
        icon: 'bullhorn',
        ref: 'po',
        target_x: this.$refs.po && this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp ? this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp.getBoundingClientRect().left : 0,
        target_y: this.$refs.po && this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp ? this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp.getBoundingClientRect().top : 0,
        room:  {      
              oid: 'funkyhelp',
              label: this.$t("Room name"),              
              sensor: {
                isOnline: 1,
                settings: {
                  enabled: true,
                },
              },
              admissionList: [
                {                  
                  stableState: { code: 0 },
                  alert: {
                    moderation: { createdAt: new Date(), trueAlert: true },
                    handling: { createdAt: null },
                    autoHandling: { createdAt: null },
                    alertType: 5,
                  },
                },
              ],
        },
        docBtnHelp: false,
        docBtnPower: false,
        docBtnEye: false,
        docBtnBed: false,
        docBtnAction: true,
        description: this.$t('To take in charge an alert, you have 2 possibilities. The first is to answer to your phone call if configured. The second one it to click on this action button.'),
      },
      {
        title: this.$t('Handle a Bed Exit alert'),
        icon: 'bullhorn',
        ref: 'po',
        target_x: this.$refs.po && this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp ? this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp.getBoundingClientRect().left : 0,
        target_y: this.$refs.po && this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp ? this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp.getBoundingClientRect().top : 0,
        room:  {      
              oid: 'funkyhelp',
              label: this.$t("Room name"),              
              sensor: {
                isOnline: 1,
                settings: {
                  enabled: true,
                },
              },
              admissionList: [
                {
                  stableState: { code: 0 },
                  alert: {
                    moderation: { createdAt: new Date(), trueAlert: true },
                    handling: { createdAt: null },
                    autoHandling: { createdAt: null },
                    alertType: 4,
                  },
                },
              ],
        },
        docBtnHelp: false,
        docBtnPower: false,
        docBtnEye: false,
        docBtnBed: false,
        docBtnAction: true,
        description: this.$t('As for Fall alerts, to take in charge an alert, you have 2 possibilities. The first is to answer to your phone call if configured. The second one it to click on this action button.'),
      },      
      {
        title: this.$t('End take in charge'),
        icon: 'bullhorn',
        ref: 'po',
        target_x: this.$refs.po && this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp ? this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp.getBoundingClientRect().left : 0,
        target_y: this.$refs.po && this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp ? this.$refs.po[0].$refs.roombtn_poweroff_funkyhelp.getBoundingClientRect().top : 0,
        room:  {      
              oid: 'funkyhelp',
              label: this.$t("Room name"),              
              sensor: {
                isOnline: 1,
                settings: {
                  enabled: true,
                },
              },
              admissionList: [
                {
                  expectingHandled: true,
                  stableState: { code: 0 },
                  alert: {
                    moderation: { createdAt: new Date(), trueAlert: true },
                    handling: { createdAt: new Date() },
                    autoHandling: { createdAt: null },
                    alertType: 4,
                  },
                },
              ],
        },
        docBtnHelp: false,
        docBtnPower: false,
        docBtnEye: false,
        docBtnBed: false,
        docBtnAction: true,
        description: this.$t('When you finished to help the patient. You should declare the end of this incident by clicking on this action button.'),
      },
      */
      
      ];
      ;
      return st;
    }
        
    
  },
  watch: {},
  methods: {
    onBtnHelp() {},
    onBtnPower() {},
    onBtnEye() {},
    onBtnBed() {},
    onBtnAction() {},
  },
  mounted() {},
  name: "ISAHelpModalContent",
};
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
}

.modal-title {
  font-weight: 700;
}
</style>


<style lang="scss" scoped>
.dg-content {
  text-align: left !important;
  font-size: 1.5rem;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>

<style lang="scss" >
.isa-link {
  padding: 0px;
}

</style>
